import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  PostFormDataRequestForPayment,
  PostFormDataRequest,
  GetAllRequest,
  DeleteByParams,
} from "../../FormRequest/request";

import {toast} from "react-toastify"

const Address = ({ onUpdate, data }) => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    pin_code: "",
    city: "",
    state: "",
    country: "India",
    address: "",
    type: "in_india",
  });
  const [responseMessage, setResponseMessage] = useState("");
  const [error, setError] = useState("");
  const [errors, setErrors] = useState({});
  const [countryCode, setCountryCode] = useState([]);
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setResponseMessage("");
    const token = localStorage.getItem("token"); // Get the token from local storage

    if (!token) {
      console.error("No token found. Please log in first.");
      setError("Please log in first.");
      return;
    }
    formData.name = formData.first_name + " " + formData.last_name;
    if (formData.country == "India") {
      formData.type = "in_india";
    } else {
      formData.type = "outsite_india";
    }
    try {
      const validationErrors = validate();
      if (Object.keys(validationErrors).length != 0) {
        setErrors(validationErrors);
      } else {
        const response = await PostFormDataRequestForPayment(
          "save-address",
          JSON.stringify(formData)
        );
        onUpdate();
        setResponseMessage(response.message);
        toast.success("Address added successfully.");
      }
    } catch (error) {
      setError(
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "Failed to save address. Please try again."
      );
      console.error(error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  useEffect(() => {
    getCountryList();
  }, []);

  const getCountryList = async (addressData) => {
    const api = "country-code";
    const address = await GetAllRequest(api);
    setCountryCode(address.data);    
  };

  const validate = () => {
    const errors = {};

    // Validate last_name
    if (formData.last_name.length < 3) {
      errors.last_name = "Last name must be at least 3 characters.";
    } else if (formData.last_name.length > 30) {
      errors.last_name = "Last name must not be greater than 30 characters.";
    }

    // Validate first_name
    if (formData.first_name.length < 3) {
      errors.first_name = "First name must be at least 3 characters.";
    } else if (formData.first_name.length > 30) {
      errors.first_name = "First name must not be greater than 30 characters.";
    }

    // Validate state
    if (formData.state.length < 3) {
      errors.state = "State must be at least 3 characters.";
    } else if (formData.state.length > 30) {
      errors.state = "State must not be greater than 30 characters.";
    }

    // Validate city
    if (formData.city.length < 3) {
      errors.city = "City must be at least 3 characters.";
    } else if (formData.city.length > 30) {
      errors.city = "City must not be greater than 30 characters.";
    }

    // Validate address
    if (formData.address.length < 3) {
      errors.address = "Address must be at least 3 characters.";
    } else if (formData.address.length > 255) {
      errors.address = "Address must not be greater than 255 characters.";
    }

    // Validate pin_code
    if (!/^\d+$/.test(formData.pin_code)) {
      errors.pin_code = "Pin code must be a number.";
    } else if (formData.pin_code.length < 6) {
      errors.pin_code = "Pin code must be at least 6 digits.";
    } else if (formData.pin_code.length > 6) {
      errors.pin_code = "Pin code must not be greater than 6 digits.";
    }

    // Validate phone_number
    if (!/^\d+$/.test(formData.phone_number)) {
      errors.phone_number = "Phone number must be a number.";
    } else if (formData.phone_number.length < 8) {
      errors.phone_number = "Phone number must be at least 8 digits.";
    } else if (formData.phone_number.length > 15) {
      errors.phone_number = "Phone number must not be greater than 15 digits.";
    }

    return errors;
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="shipping-address">
        <p>
          <b>Contact Details</b>
        </p>
        <ul className="error-list">
          {errors.first_name && <li className="error">{errors.first_name}</li>}
          {errors.last_name && <li className="error">{errors.last_name}</li>}
          {errors.state && <li className="error">{errors.state}</li>}
          {errors.city && <li className="error">{errors.city}</li>}
          {errors.address && <li className="error">{errors.address}</li>}
          {errors.pin_code && <li className="error">{errors.pin_code}</li>}
          {errors.phone_number && (
            <li className="error">{errors.phone_number}</li>
          )}
        </ul>
        <div className="input-row">
          <input
            type="text"
            name="first_name"
            placeholder="First Name"
            className="half"
            value={formData.first_name}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="last_name"
            placeholder="Last Name"
            value={formData.last_name}
            onChange={handleChange}
            className="half"
            required
          />
        </div>
        <div className="input-row">
          <input
            type="number"
            name="phone_number"
            placeholder="Phone Number"
            value={formData.phone_number}
            onChange={handleChange}
            required
          />
        </div>
        <div className="input-row">
          <input
            type="text"
            name="address"
            placeholder="Full Address"
            value={formData.address}
            onChange={handleChange}
            required
          />
        </div>
        <div className="input-row">
          <select
            name="country"
            placeholder="Country"
            value={formData.country}
            onChange={handleChange}
            className="half"
            required
          >
            {data === "in_india" ? (
              <option value="India" selected disabled>
                India
              </option>
            ) : (
              <>
                <option>Select Country</option>
                {countryCode.map((country) => (
                  <option value={country.nationality}>
                    {country.nationality}
                  </option>
                ))}
              </>
            )}
          </select>

          <input
            type="number"
            name="pin_code"
            placeholder="Pin Code"
            value={formData.pin_code}
            onChange={handleChange}
            className="half"
            required
          />
        </div>
        <div className="input-row">
          <input
            type="text"
            name="state"
            placeholder="State"
            value={formData.state}
            onChange={handleChange}
            className="half"
            required
          />

          <input
            type="text"
            name="city"
            placeholder="City"
            value={formData.city}
            onChange={handleChange}
            className="half"
            required
          />
        </div>
        <div className="checkout-actions">
          <button type="submit" className="addToCart">
            Save Address
          </button>
        </div>
      </div>
    </form>
  );
};
export default Address;
