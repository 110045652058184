import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { router } from "./routes/route";
import { tempRouter } from "./routes/tempRoute";

export default function App() {
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true"; // Ensure the value is compared as a string

  useEffect(() => {
    AOS.init({
      disable: "mobile",
    });
  }, []);

  return (
    <div className="App">
      <RouterProvider router={router} />
      <ToastContainer />
    </div>
  );
}
