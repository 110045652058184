import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {GetAllRequest} from "../FormRequest/request";

const initialState = {
  products: [],
  status: "idle",
};

const getToken = () => localStorage.getItem("token");

export const getProducts = createAsyncThunk("products/get", async () => {
  const token = getToken();
  console.log("Retrieved Token:", token); // Log the token to check if it's correct
  try {
    const response=await GetAllRequest('list-cart');
    return response?.data || []; // Return the fetched products
  } catch (error) {   
    console.error("Failed to fetch products:", error);
    if (error.response && error.response.data) {
      console.error("Error response:", error.response.data); // Log the server's error message
    }
    return [];
  }
});

const cardSlice = createSlice({
  name: "card",
  initialState,
  reducers: {
    fetchProducts(state, action) {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.fulfilled, (state, action) => {
        state.products = action.payload;
        state.status = "idle";
      })
      .addCase(getProducts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProducts.rejected, (state) => {
        state.status = "error";
      });
  },
});

export const { fetchProducts } = cardSlice.actions;
export default cardSlice.reducer;
