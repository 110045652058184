import { createBrowserRouter } from 'react-router-dom';
import TempLogin from '../components/TempLogin';

// Create the routes
export const tempRouter = createBrowserRouter([
  {
    path: '/*',
    element: <TempLogin />,
  },
]);
