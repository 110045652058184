import React, { useEffect, useState } from "react";
import "./common.css";
import { GetAllRequest } from "../../FormRequest/request";
import { useNavigate } from "react-router-dom";

const Processing = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [orderList, setOrderList] = useState([]);
  const [products, setProduct] = useState([]);
  const [trackLink, setTrackLink] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    getOrderList();
  }, []); // Empty dependency array ensures this runs once on mount

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const getOrderList = async () => {
    try {
      const apis = "order-listing?status=shipped";
      const data = await GetAllRequest(apis);
      setOrderList(data.data); // Update state with the fetched order list
    } catch (error) {
      console.error("Error fetching order list:", error);
    }
  };

  const getOrderDetails = async (id) => {
    try {
      const apis = "order-details?order_id=" + id;
      const data = await GetAllRequest(apis);
      setProduct(data.data.product);       
      setTrackLink(data?.data?.tracking_link);       
    } catch (error) {
      console.error("Error fetching order list:", error);
    }
  };

  const trackOrder = () => {  
   window.location.href=trackLink;
  };
  const writReview = (id) => {
    navigate(`/product-details/${id}`);
  };

  const viewDetails = (idD,date) => {
    const id = encodeId(idD);
    navigate(`/order-details/${id}?date=${date}`);
  };

  const encodeId = (id) => {
    return btoa(id.toString());
  };

  const formatDate = (dateString) => {   
    const date = new Date(dateString);    
    const day = date.getDate();
    const month = date.getMonth() + 1; 
    const year = date.getFullYear();
    const paddedDay = String(day).padStart(2, '0');
    const paddedMonth = String(month).padStart(2, '0');
    return `${paddedDay}-${paddedMonth}-${year}`;
  };

  

  return (
    <>
      {orderList.length > 0 ? (
        <div className="accordion">
          {orderList.map((order, index) => (
            <div className="accordion-item" key={index}>
              <div
                className="accordion-header"
                
              >
                 <div className="order-info">
                  <div className="order-header">
                    <span>Order Placed</span>
                    <span>Total</span>
                    <span>Quantity</span>
                    <span>Order#{order.orderId}</span>
                  </div>
                  <div className="order-details">
                    <span>{formatDate(order.created_at) || "29-08-2024"}</span>
                    <span>₹{order.quantity_price}</span>
                    <span>{order.total_quantity}</span>
                    <span  style={{color:"#0091E3"}} onClick={() => {
                      toggleAccordion(index);
                      getOrderDetails(order.orderId);
                    }}>{openIndex === index ? "Hide order details" :"View order details" }</span>
                  </div>
                </div>
              </div>
              {openIndex === index &&
                products.length > 0 &&
                products.map((product) => (
                  <div className="accordion-content" key={product?.id}>
                    <div
                      className="order-detail"
                    >
                      <div className="order-content">
                        <div className="wrapper">
                          <div className="order-images">
                            <div className="order-main-image">
                              <div className="image">
                                <img src={product.image} alt={product.name} />
                              </div>
                            </div>
                          </div>
                          <div className="order-info-content">
                            <div className="order-name">
                              <h1>{product?.name}</h1>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    product.description.length > 150
                                      ? `${product.description.substring(
                                          0,
                                          150
                                        )}...`
                                      : product.description,
                                }}
                              />
                              <div className="buttons">
                                <button className="order-action-button filled" onClick={()=>{writReview(product?.id)}}>
                                  Buy it again
                                </button>
                                <button className="order-action-button outlined" onClick={()=>{viewDetails(order?.orderId,order?.updated_at)}}>
                                  View your item
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="order-actions">
                          <div className="buttons">
                            <button className="order-action-button outlined" onClick={trackOrder}>
                              Track Order
                            </button>
                            <button className="order-action-button outlined" onClick={()=>{writReview(product?.id)}}>
                              Write a product review
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr style={{ borderBottom: "1px solid #D7D7D7" }} />
                  </div>
                ))}
            </div>
          ))}
        </div>
      ) : (
        <div
          className="order-product-detail-empty"
        >    
          <div className="empty-order-message">
          Oops! Order history isn’t available right now.
          </div>
         
        </div>
      )}
    </>
  );
  }  

export default Processing;
