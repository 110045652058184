import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PostFormDataRequest } from "../FormRequest/request";
import { useState } from 'react';
const initialState = {
  status: 'idle',
  error: null,
  userId:null,
};
export const forgotPassword = createAsyncThunk('/forgot-password', async (credentials,{rejectWithValue}) => {
  try {    
      const response = await PostFormDataRequest("forget-password", JSON.stringify(credentials));  
      if (!response?.status && !response?.success) {          
        return rejectWithValue(response?.message || "Something went wrong. Please try again later.");
      }else{     
      return response;
      }
    } catch (error) {      
      return rejectWithValue(error.message);
    }
});

const forgotPasswordSlice = createSlice({
  name: 'forgot-password',
  initialState,
  reducers: {
        resetState: (state) => {
          state.status = "idle";
          state.error = null;
          state.message = null;
        },
      
  },
  extraReducers: (builder) => {
    builder
      .addCase(forgotPassword.pending, (state) => {
        state.status = 'loading';
        console.log( state.status,"FFFFF");
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {      
        state.status = 'succeeded';
        state.message = action.payload.message;
        console.log( state.status,"sssssssssss");

      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.status = 'failed';      
        console.log( state.status,"ffffffff");
        state.error = action.payload;
      });
  },
});

export const { resetState } = forgotPasswordSlice.actions;
export default forgotPasswordSlice.reducer;
