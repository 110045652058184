import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword, resetState } from "../../../reducers/forgotPasswordSlice";
import Swal from "sweetalert2";
import "../forgot.css"; // Ensure this has similar styling to the login page
import logo from "../../../assets/logo.svg";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] = useState({ email: "" });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const { status, error, message } = useSelector((state) => state.forgotPassword);

  useEffect(() => {
    if (status === "succeeded") {
      setLoading(false);
      Swal.fire({
        text: message,
        icon: "success",
        confirmButtonText: "Ok",
      });
      dispatch(resetState());
      navigate("/login");
    } else if (status === "failed") {
      setLoading(false);
      setErrors({ email: error });
    }
  }, [status, dispatch, navigate, error]);

  const validate = () => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = "Email is invalid";
    }
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      dispatch(forgotPassword(values));
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <section className="forgot-page">
      <div className="forgot-container">
        <div className="forgot-box">
          <div className="forgot-header">
            <img src={logo} alt="Shivam Yoga" className="forgot-logo" />
            <h2>Forgot Password</h2>
            <p>Please enter your email to reset your password</p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                placeholder="Enter your email"
                className="form-input"
              />
              {errors.email && <p className="form-error">{errors.email}</p>}
            </div>
            <button type="submit" className="form-button" disabled={loading}>
              {loading ? "Loading..." : "Send Link"}
            </button>
            <div className="form-footer">
              <NavLink to="/login" className="form-link">
                Back to Login
              </NavLink>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
