import React from 'react'
import { Outlet,useLocation } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from '../../store/store'
import Header from '../header/Header'
import Footer from '../footer/Footer'
const Layout=()=> {
  const location = useLocation();

  const pagesWithoutHeaderSidebar = ["/login","/register","/forgot-password"];
  const isSpecialPage = pagesWithoutHeaderSidebar.includes(location.pathname);
  return (
    <div>
        <Provider store={store}>
        {!isSpecialPage && <Header />}
        <Outlet/>
        {!isSpecialPage && <Footer />}
      </Provider>
      
    </div>
  )
}

export default Layout
