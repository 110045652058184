import React, { useState } from 'react';
import './TempLogin.css'; // Make sure this path is correct
import { toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
const TempLogin = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    
    // Replace 'admin' and 'password' with your desired credentials
    if (username === 'tempadmin@yoga.com' && password === 'Lucknow@123') {
      localStorage.setItem('isLoggedIn', 'true'); 
      toast.success("Welcome to Shivam Yoga Web Panel!");
      window.location.href="/"; 

    } else {
      setError('Invalid credentials');
    }
  };

  return (
    <div className="login-container-temp">
      <h2>Temp Login</h2>
      <form onSubmit={handleLogin}>
        <div>         
          <input 
            type="text" 
            value={username} 
            onChange={(e) => setUsername(e.target.value)} 
            autoComplete='true'
            placeholder='Username'
            required 
          />
        </div>
        <div>          
          <input 
            type="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            autoComplete='true'
            placeholder='Password'
            required 
          />
        </div>
        {error && <div className="error">{error}</div>}
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default TempLogin;
