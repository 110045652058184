import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PostFormDataRequest } from "../FormRequest/request";
import { useState } from 'react';
const initialState = {
  status: 'idle',
  error: null,
  userId:null,
  message:null,
};
export const register = createAsyncThunk('/register', async (credentials,{rejectWithValue}) => {
  try {    
      const response = await PostFormDataRequest("registration", JSON.stringify(credentials));
      if (!response?.status && !response?.success) {          
        return rejectWithValue(response?.message?.email[0] || "Something went wrong. Please try again later.");
      }else{     
      return response;
      }
    } catch (error) {      
      return rejectWithValue(error.message);
    }
});

const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    resetState: (state) => {
      state.status = "idle";
      state.error = null;
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.status = 'loading';
        console.log( state.status,"FFFFF");
      })
      .addCase(register.fulfilled, (state, action) => {      
        state.status = 'succeeded';
        state.message = action.payload.message;
        console.log( state.status,"sssssssssss");

      })
      .addCase(register.rejected, (state, action) => {
        state.status = 'failed';      
        console.log( state.status,"ffffffff");
        state.error = action.payload;
      });
  },
});
export const { resetState } = registerSlice.actions;
export default registerSlice.reducer;
