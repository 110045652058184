import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetAllRequestWithParams } from "../FormRequest/request";
const initialState = {
  data: [],
  status: "idle",
};

// Async thunk for fetching products with optional sorting
export const getProducts = createAsyncThunk(
  "products/get",
  async (sortOptions) => {
    console.log(">>>>>>>>>>>>"+JSON.stringify(sortOptions));
    try {
      const params = {
        name: sortOptions?.name,
        price: sortOptions?.price,
      };
      const response = await GetAllRequestWithParams("product-listing", params);
      return response?.data?.data
        ? response?.data?.data
        : response?.data
        ? response?.data
        : [];
    } catch (error) {
      console.error("Error fetching products:", error);
      throw error; // Handle the error in the rejected state
    }
  }
);

const productSlice = createSlice({
  name: "productItem",
  initialState,
  reducers: {
    fetchProducts(state, action) {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "idle";
      })
      .addCase(getProducts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProducts.rejected, (state) => {
        state.status = "error";
      });
  },
});

export const { fetchProducts } = productSlice.actions;
export default productSlice.reducer;
