import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, resetState } from "../../../reducers/loginSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "../form.css";
import "./login.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import login_image from "../../../assets/Login.png";
import logo from "../../../assets/logo.svg";

const Form = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    device_type: "web",
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const { status, error, message } = useSelector((state) => state.login);
  const [loading, setLoading] = useState(false);

  const validate = () => {
    const errors = {};
    if (!credentials.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(credentials.email)) {
      errors.email = "Email is invalid";
    }
    if (!credentials.password) {
      errors.password = "Password is required";
    } else if (credentials.password.length < 6) {
      errors.password = "Password must be at least 6 characters";
    }
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLoginClick = (event) => {
    event.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true); // Start loading
      dispatch(login(credentials));
    } else {
      setErrors(validationErrors);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (status === "succeeded" && token) {
      setLoading(false);
      toast.success(message);
      dispatch(resetState());      
      navigate("/");     
    } else if (status === "failed") {
      setLoading(false);
      toast.error(error);
      dispatch(resetState());
    }
  }, [status, message, error, navigate, dispatch]);

  return (
    <section className="login-container">
      {/* Background Image */}
      <div
        className="login-image"
        style={{ backgroundImage: `url(${login_image})` }}
      ></div>

      {/* Form Container */}
      {/* <div className="form-container"> */}
        <div className="form-container form login ">
        <div className="logo-container">
            <img src={logo} alt="Logo" className="logo-image" />
          </div>
          <h1 className="form-header">Login to Shivam Yoga</h1>
          <p>Please enter your email or Username and password to continue</p>
          <form onSubmit={handleLoginClick}>
            {/* Email Field */}
            <div className="form-group">
              <label htmlFor="email" className="form-label">
              Email address or Username :
              </label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Enter your email"
                value={credentials.email}
                onChange={handleChange}
                className="form-input"
              />
              {errors.email && <p className="error">{errors.email}</p>}
            </div>

            {/* Password Field */}
            <div className="form-group">
              <label htmlFor="password" className="form-label">
                Password *
              </label>
              <div className="input-with-icon">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  placeholder="Enter your password"
                  value={credentials.password}
                  onChange={handleChange}
                  className="form-input"
                />
                <span
                  className="password-toggle"
                  onClick={togglePasswordVisibility}
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </span>
              </div>
              {errors.password && <p className="error">{errors.password}</p>}
            </div>

            {/* Submit Button */}
            <button type="submit" className="form-button">
              {status === "loading" ? "Logging..." : "Login"}
            </button>

            {/* Forgot Password and Register Links */}
            <p className="form-links">
              <NavLink to="/forgot-password" className="link">
                Forgot Password?
              </NavLink>
              <p>Are You a New User?<NavLink to="/register" className="link right-align">
                <b>Register now</b>
              </NavLink></p>
            </p>
          </form>
        </div>
      {/* </div> */}
    </section>
  );
};

export default Form;
