// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error {
    color: red;
    font-size: 0.875em; /* small letters */
    margin-top: 5px;
  }
  .input-with-icon {
    position: relative;
  }
  
  .input-with-icon input {
    width: 100%;
    padding-right: 40px; /* Adjust padding to make space for the icon */
  }
  
  .password-toggle {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #aaa; /* Adjust color as needed */
    font-size: 1.2em;
  }
  
  .password-field {
    position: relative;
  }
  .login-container {
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
  }
  
  @media (min-width: 768px) {
    .login-container {
      flex-direction: row;
    }
  }
  /* General container setup */

@media (min-width: 768px) {
  .login-container {
    flex-direction: row;
  }
}

/* Background Image */
.login-image {
  width: 100%;
  height: 50vh;
  background-size: cover;
  background-position: center;
}

@media (min-width: 768px) {
  .login-image {
    width: 100%;
    height: 100vh;
  }
}
  
`, "",{"version":3,"sources":["webpack://./src/components/auth/login/login.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,kBAAkB,EAAE,kBAAkB;IACtC,eAAe;EACjB;EACA;IACE,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,mBAAmB,EAAE,8CAA8C;EACrE;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,QAAQ;IACR,2BAA2B;IAC3B,eAAe;IACf,WAAW,EAAE,2BAA2B;IACxC,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;EACpB;EACA;IACE,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,yBAAyB;IACzB,gBAAgB;EAClB;;EAEA;IACE;MACE,mBAAmB;IACrB;EACF;EACA,4BAA4B;;AAE9B;EACE;IACE,mBAAmB;EACrB;AACF;;AAEA,qBAAqB;AACrB;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;EACE;IACE,WAAW;IACX,aAAa;EACf;AACF","sourcesContent":[".error {\n    color: red;\n    font-size: 0.875em; /* small letters */\n    margin-top: 5px;\n  }\n  .input-with-icon {\n    position: relative;\n  }\n  \n  .input-with-icon input {\n    width: 100%;\n    padding-right: 40px; /* Adjust padding to make space for the icon */\n  }\n  \n  .password-toggle {\n    position: absolute;\n    right: 10px;\n    top: 50%;\n    transform: translateY(-50%);\n    cursor: pointer;\n    color: #aaa; /* Adjust color as needed */\n    font-size: 1.2em;\n  }\n  \n  .password-field {\n    position: relative;\n  }\n  .login-container {\n    display: flex;\n    min-height: 100vh;\n    align-items: center;\n    justify-content: flex-end;\n    overflow: hidden;\n  }\n  \n  @media (min-width: 768px) {\n    .login-container {\n      flex-direction: row;\n    }\n  }\n  /* General container setup */\n\n@media (min-width: 768px) {\n  .login-container {\n    flex-direction: row;\n  }\n}\n\n/* Background Image */\n.login-image {\n  width: 100%;\n  height: 50vh;\n  background-size: cover;\n  background-position: center;\n}\n\n@media (min-width: 768px) {\n  .login-image {\n    width: 100%;\n    height: 100vh;\n  }\n}\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
