import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { otpVerify, resetState } from "../../../reducers/otpSlice";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./email-verification.css";
import Swal from "sweetalert2";
import { PostFormDataRequest } from "../../../FormRequest/request";

const EmailVerification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { status, error, message } = useSelector((state) => state.otpVerify);
  const [otp, setOtp] = useState({ digit1: "", digit2: "", digit3: "", digit4: "" });
  const [timeLeft, setTimeLeft] = useState(300); // 10 seconds initially
  const [resendAvailable, setResendAvailable] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { email } = location.state || {};

  // Refs for input fields
  const digit1Ref = useRef(null);
  const digit2Ref = useRef(null);
  const digit3Ref = useRef(null);
  const digit4Ref = useRef(null);

  useEffect(() => {
    let timer;
    if (!resendAvailable) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime > 0) {
            return prevTime - 1;
          } else {
            clearInterval(timer);
            setResendAvailable(true);
            return 0;
          }
        });
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [resendAvailable]);

  const restartTimer = () => {
    setTimeLeft(300); // Reset timer
    setResendAvailable(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (/^[0-9]?$/.test(value)) {
      setOtp((prevOtp) => ({
        ...prevOtp,
        [name]: value,
      }));

      if (value) {
        switch (name) {
          case "digit1":
            digit2Ref.current.focus();
            break;
          case "digit2":
            digit3Ref.current.focus();
            break;
          case "digit3":
            digit4Ref.current.focus();
            break;
          default:
            break;
        }
      }
    }
  };

  const handleKeyDown = (e) => {
    const { name, value } = e.target;

    if (e.key === "Backspace" && value === "") {
      switch (name) {
        case "digit2":
          digit1Ref.current.focus();
          break;
        case "digit3":
          digit2Ref.current.focus();
          break;
        case "digit4":
          digit3Ref.current.focus();
          break;
        default:
          break;
      }
    }
  };

  const handleSubmit = () => {
    const { digit1, digit2, digit3, digit4 } = otp;

    if (!digit1 || !digit2 || !digit3 || !digit4) {
      toast.error("Please enter the OTP.");
      return;
    }

    const fullOtp = `${digit1}${digit2}${digit3}${digit4}`;
    dispatch(otpVerify({ otp: fullOtp, email: email }));
  };

  const handleResendOtp = async () => {
    setLoading(true);
    try {
      const response = await PostFormDataRequest("resend-otp", JSON.stringify({ email: email }));
      if (response?.status) {
        toast.success(response?.message);
        setOtp({ digit1: "", digit2: "", digit3: "", digit4: "" });
        restartTimer();
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error("An error occurred while resending OTP.");
    } finally {
      setLoading(false);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  useEffect(() => {
    if (status === "succeeded") {
      Swal.fire({
        title: "",
        text: message,
        icon: "success",
        confirmButtonText: "Ok",
      });
      dispatch(resetState());
      navigate("/login");
    } else if (status === "failed") {
      toast.error(error);
    }
  }, [status, message, error, navigate, dispatch]);

  return (
    <section className="email-verification">
      <div className="container">
        <div className="wrapper">
          <div className="icon"></div>
          <h2>Verify Your Email</h2>
          <p>A 4-digit code has been sent to</p>
          <strong>{email}</strong> <NavLink to="/register">Change</NavLink>
          <div className="otp-box">
            <div className="fields">
              <input
                type="text"
                name="digit1"
                maxLength={1}
                value={otp.digit1}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                ref={digit1Ref}
              />
              <input
                type="text"
                name="digit2"
                maxLength={1}
                value={otp.digit2}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                ref={digit2Ref}
              />
              <input
                type="text"
                name="digit3"
                maxLength={1}
                value={otp.digit3}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                ref={digit3Ref}
              />
              <input
                type="text"
                name="digit4"
                maxLength={1}
                value={otp.digit4}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                ref={digit4Ref}
              />
            </div>
            {resendAvailable ? (
              <button onClick={handleResendOtp} className="addToCart">
                {loading ? "Loading..." : "Resend OTP"}
              </button>
            ) : (
              <p>
                The OTP will expire in: <span>{formatTime(timeLeft)}</span>
              </p>
            )}
          </div>
          <button className="verify" onClick={handleSubmit} disabled={status === "loading"}>
            {status === "loading" ? "Verifying..." : "Verify"}
          </button>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default EmailVerification;
