import React, { useEffect, useState } from "react";

import { GetAllRequest } from "../../FormRequest/request";

const Privacy = () => {
  const [privacy, setPrivacy] = useState([]);

  useEffect(() => {
    getPrivacy();
  }, []);

  const getPrivacy = async () => {
    const response = await GetAllRequest("privacyPolicy");
    setPrivacy(response.data);
  };

  return (
    <div className="Privacy-container" style={{padding:"25px 30px 30px 40px"}}>
      <p
        dangerouslySetInnerHTML={{
          __html: privacy?.option_value,
        }}
      />
    </div>
  );
};

export default Privacy;
