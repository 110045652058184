import React, { useEffect, useState } from "react";

import { GetAllRequest } from "../../FormRequest/request";

const Terms = () => {
  const [terms, setTerms] = useState([]);

  useEffect(() => {
    getTerms();
  }, []);

  const getTerms = async () => {
    const response = await GetAllRequest("termsANDcondition");
    setTerms(response.data);
  };

  return (
    <div className="terms-container" style={{padding:"25px 30px 30px 40px"}}>
      <p
        dangerouslySetInnerHTML={{
          __html: terms?.option_value,
        }}
      />
    </div>
  );
};

export default Terms;