import React, { useEffect, useRef, useCallback } from "react";
import "./profile.css";
import profileImage from "../../assets/profile.png";
import logoutIcon from "../../assets/logout.png";
import orderHistoryIcon from "../../assets/order-history.png";

import { Container, Nav, Navbar } from "react-bootstrap";

const Profile = ({ show, onLogout, data }) => {
  const profileRef = useRef();
  // Use useCallback to memoize handleClickOutside to prevent unnecessary re-renders
  const handleClickOutside = useCallback((event) => {
    if (profileRef.current && !profileRef.current.contains(event.target)) {
      // onLogout();
    }
  }, []);

  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show, handleClickOutside]);

  // Example name and email, replace with dynamic data if available
  const userName = data.name;
  const userEmail = data.email;

  return (
    <div className={`profile-popup ${show ? "show" : ""}`} ref={profileRef}>
      <div className="profile-content">
        <img
          src={data.profile_image ? data.profile_image : profileImage}
          className="profile-image"
        />
        <div className="profile-info">
          <h3 className="profile-name">{userName}</h3>
          <p className="profile-email">{userEmail}</p>
        </div>
        <ul className="profile-options">
          <Nav.Link href="/orders">
            <li className="profile-option">
              <span>Order History</span>
            </li>
          </Nav.Link>
        </ul>
        <li className="profile-option logout" onClick={onLogout}>
          <span>Log out</span>
        </li>
      </div>
    </div>
  );
};

export default Profile;
