import React, { useState, useEffect } from "react";
import "./Checkout.css";
import { useSelector, useDispatch } from "react-redux";
import { getProducts } from "../../reducers/cardSlice";
import { Swiper, SwiperSlide } from "swiper/react";
import { NavLink, useNavigate } from "react-router-dom";
import linkedInIcon from '../../assets/add.png'
import unlinkedInIcon from '../../assets/minus.png'
import deleteImg from "../../assets/delete.png";
import Swal from "sweetalert2";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { PostFormDataRequestForPayment, PostFormDataRequest, GetAllRequest,DeleteByParams} from "../../FormRequest/request";
import Address from "./Address";
import {toast} from "react-toastify"

const CheckoutPage = () => {
  const [shippingAddress, setShippingAddress] = useState([]);
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [addNewAddress, setAddNewAddress] = useState(false);
  const [deliveryCharge, setDeliveryCharge] = useState({});
  const [addressMethod, setAddressMethod] = useState("in_india");
  const [paymentMethod, setPaymentMethod] = useState("card");
  const { products, status } = useSelector((state) => state.card);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  /***
 * 
 * Hooks and Methods
 * 
 */

  useEffect(() => {
    checkLogin();
    dispatch(getProducts());
    getDeliveryCharges();
  }, [dispatch]);


  const checkLogin = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      Swal.fire({
        title: "Login Required",       
        icon: "info",
        confirmButtonText: "Login",
      }).then((result) => {
        if (result.isConfirmed) {
          handleLoginClick();
        }
      });
      return;
    }
  };

  const handleLoginClick = () => {
    navigate("/login");
  };

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleAddressChange = async (e) => {    
   await setAddressMethod(e.target.value);
   await getAddress(e.target.value);  
  };

  const handleAddNewAddress= (e) => {
    setAddNewAddress(e);
  };

  const paymentGateway = async () => {
    if(!deliveryAddress){
      Swal.fire({
        title: "Address Required !",
        text: "Please select or add a delivery address to continue.",
        icon: "warning",
        confirmButtonText: "Ok",
      })
    }else{
    let paymentUrl = null;
    const data = {
      delivery_fee: addressMethod === "in_india" ? deliveryCharge[0]?.in_india : deliveryCharge[0]?.outside_india,
      address_id:deliveryAddress
    };
    if (paymentMethod === "upi") {
      const res = await PostFormDataRequest('create-payment-web-link', JSON.stringify(data));
      paymentUrl = res?.payment_url;
    } else {    
      paymentUrl = await PostFormDataRequestForPayment('pay-web', JSON.stringify(data));   
    }
    //navigate(paymentUrl);

    window.location.href=paymentUrl;
  }
    
  };

  const getAddress= async (addressData) => {
    const api="listing-address?type="+addressData
    const address=  await GetAllRequest(api);
    setShippingAddress(address.data);  
  };

  const getDeliveryCharges= async () => {
    const api="delivery-charges"
    const address=  await GetAllRequest(api);
    setDeliveryCharge(address.data);  
  };


  useEffect(() => {
    getAddress(addressMethod);    
  }, []);


  // Function to update parent data
  const updateParentData = () => {
    setAddNewAddress(false);
    getAddress(addressMethod);
  };

  const remove = async (id) => {    
    try {
      const response = await DeleteByParams("delete-address", id);
      getAddress(addressMethod);      
      toast.success("Address deleted successfully.");
    } catch (error) {
      console.log("Error:", error.response?.data || error.message);
    }
  };

  const handleDeliveryAddress=(id)=>{
    setDeliveryAddress(id);
  }


/***
 * 
 * Component Start
 * 
 */




  const data = Array.isArray(products?.Data) ? products.Data : [];
  const card = data.map((product) => (
    <div
      className="col-sm-12"
      key={product.id}
      data-aos="fade-in"
      data-aos-duration="500"
    >
      <div className="checkout-product-detail card" key={product?.id}>
        <div className="checkout-product-content">
          <div className="checkout-product-images">
            <div className="checkout-main-image">
              <Swiper
                pagination={{ clickable: true }}
                modules={[Pagination]}
                className="mySwiper"
              >
                {product.productThumbnail && (
                  <SwiperSlide>
                    <div className="image">
                      <img
                        src={product.productThumbnail}
                        alt={product.productName}
                      />
                    </div>
                  </SwiperSlide>
                )}
              </Swiper>
            </div>
          </div>
          <div className="checkout-product-info">
            <div className="checkout-product-name">
              <h1>
                {product.productName.length > 20
                  ? ` ${product.productName.substring(0, 20)}...`
                  : product.productName}
              </h1>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    product.productDescription.length > 45
                      ? ` ${product.productDescription.substring(0, 45)}...`
                      : product.productDescription,
                }}
              />
              <p>Each : ₹{(product?.productDiscountedPrice/product.quantity) || (product?.productPrice/product.quantity)}</p>
              <p>Quantity : {product.quantity}</p>
            </div>
          </div>
          <div className="checkout-product-price">
            <div className="checkout-price">
              <span className="checkout-current-price">
                ₹{product?.productDiscountedPrice || product.productPrice}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  ));

  return (
    <div className="checkout-page">
      <div className="progress-indicator">
        <ul>
          <li className="step completed">
            <div>1</div>
            <span className="label">Shipping</span>
          </li>
          <li className="step">
            <div>2</div>
            <span className="label">Payment</span>
          </li>
          <li className="step">
            <div>3</div>
            <span className="label">Review</span>
          </li>
        </ul>
      </div>
      <div className="checkout-content">
        <div className="left-column">
          <div className="shipping-address">
            <h2>Shipping Address</h2>
            <hr />
            <div className="shipping-methods">            
              <div className="method">
                <input
                  type="radio"
                  name="addressMethod"
                  value="in_india"
                  checked={addressMethod === "in_india"}
                  onChange={handleAddressChange}
                />
                <label>&nbsp;&nbsp;In India</label>
              </div>
              <div className="method">
                <input
                  type="radio"
                  name="addressMethod"
                  value="outsite_india"
                  checked={addressMethod === "outsite_india"}
                  onChange={handleAddressChange}
                />
                <label>&nbsp;&nbsp;OutSide India</label>
              </div>              
            </div>
            {shippingAddress.length>0 &&
            <div className="shipping-methods">
            {shippingAddress.map((address,index) => (                    
              <div className="method">
                <input
                  type="radio"
                  name="address"
                  value={address.id}  
                  checked={deliveryAddress == address.id}
                  onClick={()=>{handleDeliveryAddress(address.id)}}          
                 
                />
                <label>&nbsp;&nbsp;{address.address} {address.city} {address.state} {address.country} , {address.pin_code}</label>
                <img
                onClick={(e) => {
                  remove(address.id);
                }}
                className="deleteImgData"
                src={deleteImg}
                alt="delete-img"
              />
              </div>                     
           
            
             ))}
              </div>
             }
            { !addNewAddress && <p className="addAddress" onClick={()=>{handleAddNewAddress(true)}}><img src={linkedInIcon}></img><span>Add New Address</span></p> }
            { addNewAddress && <p className="addAddress" onClick={()=>{handleAddNewAddress(false)}}><img src={unlinkedInIcon}></img><span>Close</span></p> }
            {addNewAddress && <Address  onUpdate={updateParentData} data={addressMethod}/>}           
            
          </div>
          <div className="shipping-methods">
            <h3>Payment Methods</h3> 
            <div className="method">
              <input
                type="radio"
                name="paymentMethod"
                value="card"
                checked={paymentMethod === "card"}
                onChange={handlePaymentMethodChange}
              />
              <label>&nbsp;&nbsp; Debit or credit card</label>
            </div>
            <div className="method">
              <input
                type="radio"
                name="paymentMethod"
                value="upi"
                checked={paymentMethod === "upi"}
                onChange={handlePaymentMethodChange}
              />
              <label>&nbsp;&nbsp; UPI</label>
            </div>
          </div>

          <div className="checkout-actions">
            <button className="addToCart" onClick={paymentGateway} type="button">
              Next
            </button>
          </div>
        </div>

        <div className="order-summary">
          <h3>Order Summary</h3>
          <div className="summary-item">
            <span>Sub Total</span>
            <span>
              <strong>₹{products?.Subtotal || 0}</strong>
            </span>
          </div>
          <div className="summary-item">
            <span>Estimated Tax</span>
            <span>₹0</span>
          </div>

          <div className="summary-item">
            <span>Delivery Charge</span>
            <span>₹{addressMethod === "in_india" ? deliveryCharge[0]?.in_india : deliveryCharge[0]?.outside_india}</span>
          </div>
          <hr />
          <div className="summary-item">
            <span>Estimated Total</span>
            <span>
            <strong>
              ₹{(products?.Subtotal || 0) + parseFloat(addressMethod === "in_india" ? deliveryCharge[0]?.in_india || 0 : deliveryCharge[0]?.outside_india || 0)}
            </strong>
            </span>
          </div>
          <hr />
          <div className="cart-item">{card}</div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPage;
