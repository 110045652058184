import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Container, Nav, Navbar } from 'react-bootstrap';
import logo from '../../assets/Logo 1.png';
import card from '../../assets/card.png';
import notification from '../../assets/Noty.png';
import { CiLogin } from "react-icons/ci";
import search from '../../assets/search.png';
import profile from '../../assets/profile.png';
import Profile from './Profile';
import { useSelector, useDispatch } from "react-redux";
import './header.css';
import Swal from 'sweetalert2';
import { toast } from "react-toastify";
import { getProducts } from "../../reducers/cardSlice";

import {
  GetRequest,GetAllRequest
} from "../../FormRequest/request";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showLinks, setShowLinks] = useState(true);
  const [user, setUser] = useState({});
  const [count, setCount] = useState(null);
  const [isDataPresent, setIsDataPresent] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const nav = useRef();
  const profileRef = useRef();
 

  useEffect(() => {   
    getUserProfile();
    const storedData = localStorage.getItem('token');
    setIsDataPresent(!!storedData);
    setShowLinks(!(location.pathname === '/' && !storedData));

    window.addEventListener('scroll', function () {
      if (window.scrollY > 10) {
        nav.current.classList.add('sticky');
      } else {
        nav.current.classList.remove('sticky');
      }
    });

    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [location]);

  const handleProfileClick = () => {
    setShowPopup((prev) => !prev);
  };

  const { products, status } = useSelector((state) => state.card);
  const dispatch = useDispatch();
  useEffect(() => {  
    const storedData = localStorage.getItem('token');
    if(storedData){
    dispatch(getProducts());
    }
  }, [dispatch]);

  

  const handleLogout = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to logout',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Logout',
    }).then(async (result) => {
      if (result.isConfirmed) {
        localStorage.removeItem('token');
        toast.success("Logout Successfully!");
        navigate('/');
        // Swal.fire('Logged Out!', 'Your account has been logged out.', 'success');
      }
    });
  };

  const getUserProfile = async () => {
    const storedData = localStorage.getItem("token");
    if (storedData) {
      const users = await GetRequest("user-profile-web", storedData); 
      if(users && users.data){
      setUser(users.data); 
      }   
    }
  };

  const handleSearch = () => {      
      navigate('/');     
      setTimeout(() => {      
        const observer = new MutationObserver((mutationsList) => {
          const element = document.getElementById('search');
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
            observer.disconnect(); 
          }
        });         
        observer.observe(document.body, { childList: true, subtree: true });
      }, 100); 
  };

  useEffect(() => {
    const data = Array.isArray(products.Data) ? products.Data : [];
    setCount(data.length);
  }, [products.Data]);
  
  return (
    <>
      {/* <Navbar expand="lg" className="navbar" ref={nav}>
        <Container>
          <Navbar.Brand to="/" as={Link}>
            <img src={logo} alt="logo" />
          </Navbar.Brand>
          <Nav className="me-auto">
          <Nav.Link href="/">Home</Nav.Link>
          <Nav.Link href="/about-us">About Us</Nav.Link>
          <Nav.Link href="/courses">Courses</Nav.Link>
          <Nav.Link href="/gallery">Gallery</Nav.Link>
          <Nav.Link href="/shop">Shop</Nav.Link>
          </Nav>
          <div className="search-bar-container">
            {location.pathname === "/" ? (
              <>
              <Nav.Link href="#search" className="search-icon">
                <input type="text" placeholder="Search" className="search-input" />
            </Nav.Link>
          </>
            ) : (
              <Nav.Link onClick={handleSearch} className="search-icon">
                <input type="text" placeholder="Search" className="search-input" />
                </Nav.Link>
            )}
          </div>

          <Navbar.Text>
          <Nav className="align-items-center">
            {location.pathname === "/" ? (
              <Nav.Link href="#search" className="nav-link-spaced">
                <button className="search-btn">
                  <img src={search} alt="logo" />
                </button>
              </Nav.Link>
            ) : (
              <Nav.Link onClick={handleSearch} className="nav-link-spaced">
                <img src={search} alt="logo" />
              </Nav.Link>
            )}
            <Nav.Link to="/cart" as={Link} className="nav-link-spaced">
              <img src={card} alt="logo" />
              {isDataPresent && showLinks && (
                <span className="badge">{count > 0 ? count : ""}</span>
              )}
            </Nav.Link>

            {!isDataPresent && (
              <Nav.Link href="/login" className="nav-link-spaced">
                <CiLogin />
              </Nav.Link>
            )}
            {isDataPresent && showLinks && (
              <Nav.Link
                className="profile nav-link-spaced"
                onClick={handleProfileClick}
                ref={profileRef}
              >
                <img src={user.profile_image ? user.profile_image : profile} />
              </Nav.Link>
            )}
          </Nav>
            <Profile show={showPopup} data={user} onLogout={handleLogout} />
          </Navbar.Text>
        </Container>
      </Navbar> */}

       <Navbar expand="lg" className="navbar" ref={nav}>
        <Container>
          <Navbar.Brand to="/" as={Link}>
            <img src={logo} alt="logo" />
          </Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link 
              href="/" 
              className={location.pathname === "/" ? "active" : ""}
            >
              Home
            </Nav.Link>
            <Nav.Link 
              href="/about-us" 
              className={location.pathname === "/about-us" ? "active" : ""}
            >
              About Us
            </Nav.Link>
            <Nav.Link 
              href="/courses" 
              className={location.pathname === "/courses" ? "active" : ""}
            >
              Courses
            </Nav.Link>
            <Nav.Link 
              href="/gallery" 
              className={location.pathname === "/gallery" ? "active" : ""}
            >
              Gallery
            </Nav.Link>
            <Nav.Link 
              href="/shop" 
              className={location.pathname === "/shop" ? "active" : ""}
            >
              Shop
            </Nav.Link>
          </Nav>
          <div className="search-bar-container">
            {location.pathname === "/" ? (
              <>
                <Nav.Link href="#search" className="search-icon">
                  <input type="text" placeholder="Search" className="search-input" />
                </Nav.Link>
              </>
            ) : (
              <Nav.Link onClick={handleSearch} className="search-icon">
                <input type="text" placeholder="Search" className="search-input" />
              </Nav.Link>
            )}
          </div>

          <Navbar.Text>
            <Nav className="align-items-center">
              {location.pathname === "/" ? (
                <Nav.Link href="#search" className="nav-link-spaced">
                  <button className="search-btn">
                    <img src={search} alt="logo" />
                  </button>
                </Nav.Link>
              ) : (
                <Nav.Link onClick={handleSearch} className="nav-link-spaced">
                  <img src={search} alt="logo" />
                </Nav.Link>
              )}
              <Nav.Link to="/cart" as={Link} className="nav-link-spaced">
                <img src={card} alt="logo" />
                {isDataPresent && showLinks && (
                  <span className="badge">{count > 0 ? count : ""}</span>
                )}
              </Nav.Link>

              {!isDataPresent && (
                <Nav.Link href="/login" className="nav-link-spaced">
                  {/* <CiLogin /> */}Login / SignUp
                </Nav.Link>
              )}
              {isDataPresent && showLinks && (
                <Nav.Link
                  className="profile nav-link-spaced"
                  onClick={handleProfileClick}
                  ref={profileRef}
                >
                  <img src={user.profile_image ? user.profile_image : profile} />
                </Nav.Link>
              )}
            </Nav>
            <Profile show={showPopup} data={user} onLogout={handleLogout} />
          </Navbar.Text>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
