// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-with-icon {
  position: relative;
}

.input-with-icon input {
  width: 100%;
  padding-right: 40px; /* Adjust padding to make space for the icon */
}

.password-toggle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #aaa;
  font-size: 1.2em;
}

.password-field {
  position: relative;
}

.error {
  color: #f00;
  font-size: 16px;
  margin-top: 5px;
}

.loader {
  text-align: center;
  margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/auth/register/register.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,mBAAmB,EAAE,8CAA8C;AACrE;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,QAAQ;EACR,2BAA2B;EAC3B,eAAe;EACf,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".input-with-icon {\n  position: relative;\n}\n\n.input-with-icon input {\n  width: 100%;\n  padding-right: 40px; /* Adjust padding to make space for the icon */\n}\n\n.password-toggle {\n  position: absolute;\n  right: 10px;\n  top: 50%;\n  transform: translateY(-50%);\n  cursor: pointer;\n  color: #aaa;\n  font-size: 1.2em;\n}\n\n.password-field {\n  position: relative;\n}\n\n.error {\n  color: #f00;\n  font-size: 16px;\n  margin-top: 5px;\n}\n\n.loader {\n  text-align: center;\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
