import React, { useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from "react-router-dom";
// import required modules
import { Navigation } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import "./overview.css";



const Overview = ({ product }) => {
  const navigate = useNavigate();
  const productDetail = (event, product) => {  
    event.stopPropagation();
    navigate(`/product-details/${product.id}`);
    window.scrollTo(0, 0);
  };
  return (
    <div className="product-detail-container">
      <div className="quick-overview">
        <h3>Quick Overview</h3>
        {product?.product_data?.length > 0 ? (
          product.product_data.map((pi, index) => (
            <>
              <table key={index}>
                <tr>
                  <td>
                    <strong>Colour:</strong>
                  </td>
                  <td>{pi.colour || "Not specified"}</td>
                </tr>

                <tr>
                  <td>
                    <strong>Material:</strong>
                  </td>
                  <td>{pi.material || "Not specified"}</td>
                </tr>

                <tr>
                  <td>
                    <strong>Product Care:</strong>
                  </td>
                  <td>{pi.product_care || "Not specified"}</td>
                </tr>

                <tr>
                  <td>
                    <strong>Item Weight:</strong>
                  </td>
                  <td>{pi.product_weight || "Not specified"}</td>
                </tr>
              </table>
              <p
                className="product-description"
                dangerouslySetInnerHTML={{
                  __html: pi.description || "Not specified",
                }}
              ></p>
            </>
          ))
        ) : (
          <p>No product data available.</p>
        )}
      </div>

      <div className="related-products">
        <h3>Related Products</h3>
        <Swiper
          spaceBetween={20}
          slidesPerView={4}
          modules={[Navigation]}
          breakpoints={{
            1440: { slidesPerView: 4 },
            1200: { slidesPerView: 4 },
            1024: { slidesPerView: 3 },
            768: { slidesPerView: 2 },
            576: { slidesPerView: 1 },
            320: { slidesPerView: 1 },
          }}
        >
          {product?.relatedProducts &&
            product?.relatedProducts.map((pi, index) => (
              <SwiperSlide key={index}>
                <div className="product-card"  onClick={(event) => productDetail(event, pi)} >
                  <img
                    src={pi.thumbnail}
                    alt={pi.name}
                    className="product-image"
                  />
                  <div className="product-info">
                    {/* <div className="product-rating">
                      <span className="rating-star">⭐</span>
                      <span className="rating-score">4.0</span>
                    </div> */}
                    
                    <p className="product-name">
                      {pi.name.length > 40
                        ? `${pi.name.substring(0, 40)}...`
                        : pi.name}
                    </p>
                    <div className="product-price">
                       { pi.discounted_price ? ( 
                        <>                     
                        <span className="original-price">
                           ₹{pi.price}
                        </span>                     
                        <span className="discounted-price">
                           ₹{pi.discounted_price}
                        </span>  
                        </>                    
                        ) : (
                        <span className="discounted-price">
                           ₹{pi.price}
                        </span> 
                        )}

                       
                    </div>
                    <div className="product-discount">{pi.discount}% Off</div>
                    <div className="color-options">
                      {pi.colors &&
                        pi.colors.map((color, index) => (
                          <span
                            key={index}
                            className="color-dot"
                            style={{ backgroundColor: color }}
                          ></span>
                        ))}
                    </div>
                    {/* <button className="add-to-cart-btn">Add to Cart</button> */}
                  </div>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Overview;
