import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getProducts } from "../../reducers/cardSlice";
import "./cart.css";
import deleteImg from "../../assets/delete.png";
import CartEmpty from "../../assets/empty-cart.png";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import { DeleteByParams, PostFormDataRequest } from "../../FormRequest/request";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { NavLink } from "react-bootstrap";
import Loader from "../Loader/Loader";

const Carts = () => {
  const navigate = useNavigate();
  const { products, status } = useSelector((state) => state.card);
  const dispatch = useDispatch();
  useEffect(() => {
    checkLogin();
    dispatch(getProducts());
  }, [dispatch]);

  const remove = async (id) => {
    try {
      await DeleteByParams("remove-cart", id);
      toast.success("Item Deleted Successfully!");
      dispatch(getProducts());
    } catch (error) {
      console.log("Error:", error.response?.data || error.message);
    }
  };

  const handleIncrement = async (id, quantity) => {
    const params = {
      cart_id: id,
      quantity: 1,
    };
    await PostFormDataRequest("increase-quantity-cart", JSON.stringify(params));
    dispatch(getProducts());
  };
  const handleDecrement = async (id, quantity) => {
    const params = {
      cart_id: id,
      quantity: 1,
    };
    await PostFormDataRequest("decrease-quantity-cart", JSON.stringify(params));
    dispatch(getProducts());
  };

  const checkout = async () => {
    navigate("/checkout");
  };

  const checkLogin = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      Swal.fire({
        title: "Login Required",      
        icon: "info",
        confirmButtonText: "Login",
      }).then((result) => {
        if (result.isConfirmed) {
          handleLoginClick();
        }
      });
      return;
    }
  };
  const handleLoginClick = () => {
    navigate("/login");
  };

  // Safely access the Data array
  const data = Array.isArray(products.Data) ? products.Data : [];

  const card =
    data.length > 0 &&
    data.map((product, index) => (
      <>
        {index == 0 && (
          <div className="cart-subtotal-content">
            <div className="cart-subtotal-heading">
              <span>
                <b>{data.length}</b> Item in Cart
              </span>
            </div>
            <div className="cart-subtotal-price">
              <span>Sub Total</span>
              <br />
              <span>
                <b> ₹ {products.Subtotal}</b>
              </span>
            </div>
          </div>
        )}
        <div
          className="cart-product-detail card"
          key={product?.id}
          data-aos="fade-in"
          data-aos-duration="500"
        >
          <div className="cart-product-content">
            <div className="cart-product-images">
              <div className="cart-main-image">
                <div className="image">
                  <img
                    src={product.productThumbnail}
                    alt={product.productName}
                  />
                </div>
              </div>
            </div>
            <div className="cart-product-info">
              <div className="cart-product-name">
                <h1>{product?.productName}</h1>

                {/* <span>
                <b>Color:</b> Red
              </span> */}

                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      product.productDescription.length > 150
                        ? `${product.productDescription.substring(0, 150)}...`
                        : product.productDescription,
                  }}
                />
              </div>
              <div className="cart-quantity">
                <div className="cart-quantity-controls">
                  <button
                    onClick={(e) => {
                      handleDecrement(product.id, product.quantity);
                    }}
                  >
                    -
                  </button>
                  &nbsp;&nbsp;
                  <input
                    type="number"
                    id="qty"
                    name="qty"
                    min="1"
                    value={product.quantity}
                    readOnly
                  />
                  <button
                    onClick={(e) => {
                      handleIncrement(product.id, product.quantity);
                    }}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
            <div className="cart-product-price">
              <div className="cart-price">
                <span className="cart-current-price">
                  ₹{product?.productDiscountedPrice || product.productPrice}
                </span>
              </div>
              <div className="deleteImg">
                <img
                  onClick={(e) => {
                    remove(product.id);
                  }}
                  src={deleteImg}
                  alt="delete-img"
                />
              </div>
            </div>
          </div>
        </div>

        {data.length == index + 1 && (
          <div className="cart-checkout-content">
            <button className="addToCart" onClick={checkout}>
              Checkout
            </button>
          </div>
        )}
      </>
    ));

  return (
 
    <div className="container">
    {status === "idle" && data.length > 0 ? (
      card
    ) : (
      <div
        className="cart-product-detail-empty"
        data-aos="fade-in"
        data-aos-duration="500"
      >
        {status != "loading" ? (
          <>
            <img src={CartEmpty} alt="Empty Cart" />
            <div className="empty-cart-message">
              Oops! Your cart is empty. Start shopping now!
            </div>
            <button className="shop-now-button">
              <NavLink href="/">Shop Now</NavLink>
            </button>
          </>
        ) : (
          <Loader/>
        )}
      </div>
    )}
  </div>
  
  );
};

export default Carts;
