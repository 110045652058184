import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PostFormDataRequest } from "../FormRequest/request";

const initialState = {
  status: 'idle',
  error: null,
  userId: null,
};

export const otpVerify = createAsyncThunk(
  '/otp-verify',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await PostFormDataRequest("otp-verify", JSON.stringify(credentials));
      if (!response?.status) {          
        return rejectWithValue(response?.message || "Something went wrong. Please try again later.");
      }else{     
      return response;
      }      
    } catch (error) {
      // Extract and format error message from server if available
      const errorMessage = error.response?.data?.message || error.message || "An error occurred.";
      return rejectWithValue(errorMessage);
    }
  }
);

const otpSlice = createSlice({
  name: 'otpVerify',
  initialState,
  reducers: {
    resetState: (state) => {
      state.status = "idle";
      state.error = null;
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(otpVerify.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(otpVerify.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.message = action.payload.message;
        // Optionally handle the response token or other data
        // state.userId = action.payload.userId;
      })
      .addCase(otpVerify.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
        
      });
  },
});
export const { resetState } = otpSlice.actions;
export default otpSlice.reducer;
