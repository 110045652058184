import React from "react";
import "./order.css";
import NewOrders from "./NewOrders";
import Cancelled from "./Cancelled";
import Processing from "./Processing";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Swal from "sweetalert2";

const Orders = () => {
 const navigate=useNavigate();
  useEffect(() => {
    checkLogin();  
  }, []);


  const checkLogin = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      Swal.fire({
        title: "Login Required",       
        icon: "info",
        confirmButtonText: "Login",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/login");
        }
      });
      return;
    }
  };


  
  return (
    <div className="orders-page">
      <div className="container">
        <h4>Order History</h4>
        <div className="orders-content">
          <ul className="orders-nav-tabs" id="ordersTab" role="tablist">
            <li className="orders-nav-item" role="presentation">
              <button
                className="orders-nav-tab active"
                id="delivered-tab"
                data-bs-toggle="tab"
                data-bs-target="#delivered"
                type="button"
                role="tab"
                aria-controls="delivered"
                aria-selected="true"
              >
                Orders
              </button>
            </li>
            <li className="orders-nav-item" role="presentation">
              <button
                className="orders-nav-tab"
                id="cancelled-tab"
                data-bs-toggle="tab"
                data-bs-target="#cancelled"
                type="button"
                role="tab"
                aria-controls="cancelled"
                aria-selected="false"
              >
                Processing
              </button>
            </li>
            <li className="orders-nav-item" role="presentation">
              <button
                className="orders-nav-tab"
                id="shipped-tab"
                data-bs-toggle="tab"
                data-bs-target="#shipped"
                type="button"
                role="tab"
                aria-controls="shipped"
                aria-selected="false"
              >
                Cancelled
              </button>
            </li>
          </ul>
          <div className="orders-tab-content tab-content" id="ordersTabContent">
            <div
              className="orders-tab-pane tab-pane fade show active"
              id="delivered"
              role="tabpanel"
              aria-labelledby="delivered-tab"
            >
              <NewOrders />
            </div>
            <div
              className="orders-tab-pane tab-pane fade"
              id="cancelled"
              role="tabpanel"
              aria-labelledby="cancelled-tab"
            >
              <Processing />
            </div>
            <div
              className="orders-tab-pane tab-pane fade"
              id="shipped"
              role="tabpanel"
              aria-labelledby="shipped-tab"
            >
              <Cancelled />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
