import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { register,resetState } from "../../../reducers/registerSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import register_image from "../../../assets/Register.png";
import logo from "../../../assets/logo.svg";
import "../form.css";
import "./register.css";
import Swal from "sweetalert2";
const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    confirm_password: "",
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { status,error,message } = useSelector((state) => state.register);

  useEffect(() => {   
    if (status === "succeeded") {
      setLoading(false); // Stop loading      
      Swal.fire({
        title: "",
        text: message,
        icon: "success",
        confirmButtonText: "Ok",
      })
      dispatch(resetState());
      navigate("/email-verification", { state: { email: values.email } });
    }else if(status==="failed"){
      setLoading(false);
      setErrors({email:error});
    }
  }, [status, navigate, values.email]);

  const validate = () => {
    const errors = {};
    if (!values.name) {
      errors.name = "Name is required";
    }
    if (!values.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = "Email is invalid";
    }
    if (!values.password) {
      errors.password = "Password is required";
    } else if (values.password.length < 6) {
      errors.password = "Password must be at least 6 characters";
    }
    if (!values.confirm_password) {
      errors.confirm_password = "Confirm Password is required";
    } else if (values.confirm_password !== values.password) {
      errors.confirm_password = "Passwords do not match";
    }
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleRegister = (event) => {
    event.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true); // Start loading
      dispatch(register(values));
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <section className="register-container">
      {/* Left Side - Image */}
      <div
        className="register-image"
        style={{ backgroundImage: `url(${register_image})` }}
      ></div>

      {/* Right Side - Form */}
      <div className="register-form">
        <div className="">
          {/* Logo */}
          <div className="logo-container">
            <img src={logo} alt="Logo" className="logo-image" />
          </div>

          {/* Title */}
          <h2 className="form-title">Enter Your Details Below:</h2>

          {/* Form */}
          <form onSubmit={handleRegister}>
            {/* Name */}
            <div className="form-group">
              <label htmlFor="name">Name *</label>
              <input
                type="text"
                name="name"
                id="name"
                value={values.name}
                onChange={handleChange}
                placeholder="Enter your name here"
                className="form-input"
              />
              {errors.name && <div className="form-error">{errors.name}</div>}
            </div>

            {/* Email */}
            <div className="form-group">
              <label htmlFor="email">Email Address *</label>
              <input
                type="email"
                name="email"
                id="email"
                value={values.email}
                onChange={handleChange}
                placeholder="Enter your email here"
                className="form-input"
              />
              {errors.email && <div className="form-error">{errors.email}</div>}
            </div>

            {/* Password */}
            <div className="form-group">
              <label htmlFor="password">Password *</label>
              <div className="password-container">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  value={values.password}
                  onChange={handleChange}
                  placeholder="Enter your password"
                  className="form-input"
                />
                <span
                  className="password-toggle"
                  onClick={togglePasswordVisibility}
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </span>
              </div>
              {errors.password && (
                <div className="form-error">{errors.password}</div>
              )}
            </div>

            {/* Confirm Password */}
            <div className="form-group">
              <label htmlFor="confirm_password">Confirm Password *</label>
              <div className="password-container">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirm_password"
                  id="confirm_password"
                  value={values.confirm_password}
                  onChange={handleChange}
                  placeholder="Re-enter your password"
                  className="form-input"
                />
                <span
                  className="password-toggle"
                  onClick={toggleConfirmPasswordVisibility}
                >
                  <FontAwesomeIcon
                    icon={showConfirmPassword ? faEyeSlash : faEye}
                  />
                </span>
              </div>
              {errors.confirm_password && (
                <div className="form-error">{errors.confirm_password}</div>
              )}
            </div>

            {/* Submit Button */}
            <div className="form-group">
              <button
                type="submit"
                className="form-button"
                disabled={loading}
              >
                {loading ? "Loading..." : "Register"}
              </button>
            </div>

            {/* Link to Login */}
            <p className="form-link">
              Already have an account?{" "}
              <NavLink to="/login" className="link">
                Login
              </NavLink>
            </p>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Register;
