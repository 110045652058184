import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PostFormDataRequest } from "../FormRequest/request";
const initialState = {
  status: 'idle',
  error: null,
  userId:null,
  message:null,
};
export const login = createAsyncThunk('/login', async (credentials,{rejectWithValue}) => {
  try {
    const response= await PostFormDataRequest("login", JSON.stringify(credentials));  
    if (!response?.status && !response?.success) {          
      return rejectWithValue(response?.message || "Something went wrong. Please try again later.");
    }else{     
      localStorage.setItem('token', response?.data?.token?.accessToken);
      localStorage.setItem('authId', response?.data?.token?.token?.user_id);
      return response; 
      }
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    resetState: (state) => {
      state.status = "idle";
      state.error = null;
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = 'succeeded';        
        state.message=action.payload.message
      
      })
      .addCase(login.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;        
       console.log("Failed"+action.payload);

      });
  },
});
export const { resetState } = loginSlice.actions;
export default loginSlice.reducer;
