import React, { useState, useEffect } from "react";
import "./orderDetails.css";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';

import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import {
  GetAllRequest,
} from "../../FormRequest/request";
import { useParams } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { CiLocationOn } from "react-icons/ci";
import { MdKeyboardArrowDown,MdKeyboardArrowUp  } from "react-icons/md";

const OrderDetails = () => {
  const [shippingAddress, setShippingAddress] = useState([]);
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [addNewAddress, setAddNewAddress] = useState(false);
  const [deliveryCharge, setDeliveryCharge] = useState({});
  const [addressMethod, setAddressMethod] = useState("in_india");
  const [totalPayment, setTotalPayment] = useState(null);
  const [products, setProduct] = useState([]);
  let { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();  
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get('date');

     id=atob(id)
  /***
   *
   * Hooks and Methods
   *
   */

  useEffect(() => {
    checkLogin();
    getOrderDetails();
    getDeliveryCharges();
  }, [dispatch]);

  const checkLogin = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      Swal.fire({
        title: "Login Required",       
        icon: "info",
        confirmButtonText: "Login",
      }).then((result) => {
        if (result.isConfirmed) {
          handleLoginClick();
        }
      });
      return;
    }
  };

  const handleLoginClick = () => {
    navigate("/login");
  };

  const getOrderDetails = async () => {
    try {
      const apis = "order-details?order_id=" + id;
      const data = await GetAllRequest(apis);
      if (data && data.data && data.data.product) {
        setProduct(data.data.product);
        setDeliveryAddress(data?.data?.address);
        setTotalPayment(data?.data?.total_price);
      } else {
        setProduct([]);
      }
    } catch (error) {
      console.error("Error fetching order list:", error);
    }
  };

  const getDeliveryCharges = async () => {
    const api = "delivery-charges";
    const address = await GetAllRequest(api);
    setDeliveryCharge(address.data);
  };

  const [showDetails, setShowDetails] = useState(true);
  // Step 2: Toggle visibility function
  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const goBack = () => {
    navigate("/orders");
  };


  const formatDate = (dateString) => {   
    const date = new Date(dateString);   
    const day = date.getDate();
    const year = date.getFullYear();
    const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const month = months[date.getMonth()];
    return `${day} ${month} ${year}`;
  };
  


  /***
   *
   * Component Start
   *
   */

  const card =
    products.length > 0 &&
    products.map((product) => (
      <div
        className="col-sm-12"
        key={product?.id}
        data-aos="fade-in"
        data-aos-duration="500"
      >
        <div className="checkout-product-detail card" key={product?.id}>
          <div className="checkout-product-content">
            <div className="checkout-product-images">
              <div className="checkout-main-image">
                <Swiper
                  pagination={{ clickable: true }}
                  modules={[Pagination]}
                  className="mySwiper"
                >
                  {product?.image && (
                    <SwiperSlide>
                      <div className="image">
                        <img
                          src={product.image}
                          alt={product.image || "Product Image"}
                        />
                      </div>
                    </SwiperSlide>
                  )}
                </Swiper>
              </div>
            </div>
            <div className="checkout-product-info">
              <div className="checkout-product-name">
                <h1>
                  {product?.name?.length > 20
                    ? `${product.name.substring(0, 20)}...`
                    : product?.name || "Unnamed Product"}
                </h1>
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      product?.description?.length > 45
                        ? `${product.description.substring(0, 45)}...`
                        : product?.description || "No Description",
                  }}
                />
                <p>
                  Each : ₹
                  {product?.discounted_price / product?.quantity ||
                    product?.price / product?.quantity}
                </p>
                <p>Quantity : {product?.quantity}</p>
              </div>
            </div>
            <div className="checkout-product-price">
              <div className="checkout-price">
                <span className="checkout-current-price">
                  ₹{product?.discounted_price || product?.price}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));

  return (
    <div className="checkout-page">
      <h4><IoMdArrowBack onClick={goBack} style={{cursor:"pointer"}}/> <b>Order #{id}</b></h4>
      <div className="checkout-content">
        <div className="left-column">
        <div className="shipping-address">
        <div className="status-container">
            <div className="shipping-status">
            <CiLocationOn className="icon" />
            <span className="status-text">Delivered</span>
            </div>
            <span className="details-link" onClick={toggleDetails}>
                {showDetails ? 'Hide Details' : 'Show Details'}
                {showDetails ?<MdKeyboardArrowUp className="icon-size-page"/>:<MdKeyboardArrowDown className="icon-size-page"/>}
                </span>
        </div>
        <div className="dotted-line-container">
            <span className="dotted-line"></span>
            <span className="date-text">{formatDate(paramValue)}</span>
        </div>            
        </div>
        {showDetails && (
        <div className="shipping-methods">
          <h3 style={{ marginBottom: "30px" }}>Order Details</h3>

          <div className="contact-payment">
            <div className="contact-information">
              <p className="section-title">Contact Information</p>
              <p className="info-item">Shivam Sharma</p>
              <p className="info-item">shivamyoga@gmail.com</p>
            </div>

            <div className="payment-method">
              <p className="section-title">Payment Method</p>
              <p className="info-item">UPI / Debit Or Credit Card</p>
            </div>
          </div>

          <div className="addresses">
            <div className="address-container">
              <p className="section-title">Shipping Address</p>
              <p className="info-item">{deliveryAddress?.address}</p>
              <p className="info-item">
                {deliveryAddress?.city}, {deliveryAddress?.country}
              </p>
              <p className="info-item">{deliveryAddress?.pin_code}</p>
            </div>

            <div className="address-container">
              <p className="section-title">Billing Address</p>
              <p className="info-item">{deliveryAddress?.address}</p>
              <p className="info-item">
                {deliveryAddress?.city}, {deliveryAddress?.country}
              </p>
              <p className="info-item">{deliveryAddress?.pin_code}</p>
            </div>
          </div>

          <div className="method-payment">
            <div className="contact-information">
              <p className="section-title">Shipping Method</p>
              <p className="info-item">----</p>
            </div>
          </div>
        </div>
      )}
        </div>
        <div className="order-summary">
          <div className="summary-item">
            <span>Sub Total</span>
            <span>
              <strong>₹{totalPayment || 0}</strong>
            </span>
          </div>
          <div className="summary-item">
            <span>Estimated Tax</span>
            <span>₹0</span>
          </div>

          <div className="summary-item">
            <span>Delivery Charge</span>
            <span>
              ₹
              {deliveryAddress &&
                (deliveryAddress.type === "in_india"
                  ? deliveryCharge[0]?.in_india
                  : deliveryCharge[0]?.outside_india)}
            </span>
          </div>
          <hr />
          <div className="summary-item">
            <span>Estimated Total</span>
            <span>
              <strong>
                ₹
                {totalPayment +
                  parseFloat(
                    deliveryAddress &&
                      (deliveryAddress.type === "in_india"
                        ? deliveryCharge[0]?.in_india
                        : deliveryCharge[0]?.outside_india)
                  )}
              </strong>
            </span>
          </div>
          <hr />
          <div className="cart-item">{card}</div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
