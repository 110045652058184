import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTeamList } from "../../reducers/aboutSlice";
import "./about.css";
import missionImage from "../../assets/Login.png";
import logo from "../../assets/logo.svg";
import footerBackground from "../../assets/Login.png";

const AboutUs = () => {
  const dispatch = useDispatch();
  const { meetOurTeams, status } = useSelector((state) => state.about);

  useEffect(() => {
    dispatch(getTeamList());
  }, [dispatch]);

  return (
    <div className="about-us">
      {/* About Section */}
      <section className="about-section">
        <div className="about-background">
          <h1>About Us</h1>
          <p>Let the threads of life get connected with yoga</p>
        </div>
      </section>

      {/* Mission and Vision */}
      <section className="mission-vision">
        <div className="mission">
          <div className="text-content">
            <h2>
              <span>Our Mission</span>
            </h2>
            <h3>Inspiring Wellness, One Breath at a Time</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur. Faucibus condimentum metus
              id diam et urna pretium malesuada mauris. Dictum proin lorem
              tellus ullamcorper diam suspendisse quis tempus.
            </p>
          </div>
          <img src={missionImage} alt="Mission" />
        </div>
        <div className="vision">
          <img src={missionImage} alt="Vision" />
          <div className="text-content">
            <h2>
              <span>Our Vision</span>
            </h2>
            <h3>A Healthier, Harmonious World Through Yoga</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur. Faucibus condimentum metus
              id diam et urna pretium malesuada mauris. Dictum proin lorem
              tellus ullamcorper diam suspendisse quis tempus.
            </p>
          </div>
        </div>
      </section>

      {/* Experience Balance */}
      <section className="experience-balance">
        <h2>Experience Balance</h2>
        <div className="features">
          <div className="feature">
            <img src={logo} alt="Mind Icon" />
            <h3>Mind</h3>
            <p>Learn yoga to find balance.</p>
          </div>
          <div className="feature">
            <img src={logo} alt="Mental Health Icon" />
            <h3>Mental Health</h3>
            <p>Learn yoga for mental clarity.</p>
          </div>
          <div className="feature">
            <img src={logo} alt="Body Icon" />
            <h3>Body</h3>
            <p>Improve physical strength.</p>
          </div>
        </div>
      </section>

      {/* Team Section */}
      <section className="team-section">
        <h2>Meet Our Team</h2>
        <p className="team-intro">Guiding You on Your Yoga Journey</p>
        <div className="team">
          {status === "loading" ? (
            <p>Loading...</p>
          ) : status === "error" ? (
            <p>Failed to load team members.</p>
          ) : (
            meetOurTeams.map((member) => (
              <div className="team-member" key={member.id}>
                <img src={member.image || logo} alt={member.name} />
                <h3>{member.name}</h3>
                <p>{member.designation}</p>
                <DescriptionText description={member.description} />
              </div>
            ))
          )}
        </div>
      </section>

      {/* Footer */}
      <footer
        className="footer"
        style={{ backgroundImage: `url(${footerBackground})` }}
      >
        <div className="footer-content">
          <h2>Discover the Power of Presence with Shivam Yoga</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur. Faucibus condimentum metus
            id diam et urna pretium malesuada mauris.
          </p>
          <button>Join Our Course</button>
        </div>
      </footer>
    </div>
  );
};
// DescriptionText Component
const DescriptionText = ({ description }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleReadMore = () => setIsExpanded(!isExpanded);
  return (
    <p className="description">
      {isExpanded ? description : `${description.slice(0, 100)}...`}
      <a onClick={toggleReadMore} className="read-more-btn">
        {isExpanded ? "Read Less" : "Read More"}
      </a>
    </p>
  );
};

export default AboutUs;
