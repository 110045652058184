import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./product-detail.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper/modules';
import Overview from "../productOverview/Overview";
import Review from "../productReview/Review";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { PostFormDataRequest } from "../../FormRequest/request";
import { toast } from "react-toastify";
import {useDispatch } from "react-redux";
import { getProducts } from "../../reducers/cardSlice";
import checkedIcon from "../../assets/checked.png"
import starIcon from "../../assets/star.svg";
import "swiper/css";
import 'swiper/css/navigation';

const ProductDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [product, setProduct] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [selectedColor, setSelectedColor] = useState([]);
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedImage, setSelectedImage] = useState([]);
  const [selectedSizeImage, setSelectedSizeImage] = useState([]);
  const [availableSizes, setAvailableSizes] = useState([]);
  const [imageId, setImageId] = useState("");
  const dispatch = useDispatch();
  

  useEffect(() => {
    fetchData(id);
    window.scrollTo(0, 0);
  }, [id]);
  const fetchData = async (productId) => {
    try {
      var data = { product_id: productId };
      const response = await PostFormDataRequest(
        "view-product",
        JSON.stringify(data)
      );
      setProduct(response?.data || []);
    } catch (error) {
      console.error("Error fetching data:", error);
      setProduct([]);
    }
  };

  useEffect(() => {
    if (
      product?.product_data?.[0]?.productImage?.[0]?.multiple_images?.length > 0
    ) {
      let imageData =
        product?.product_data?.[0]?.productImage?.[0]?.multiple_images?.map(
          (data) => ({
            image: ` ${product?.product_data?.[0]?.productImagePath}/${data?.image}`,
          })
        );
      setSelectedImage(imageData);
      if (product?.product_data?.[0]?.productImage?.length > 0) {
        const sizeData =
          product?.product_data?.[0]?.productImage[0]?.product_image_size || [];
        setAvailableSizes(sizeData);
      }
    } else {     
      setSelectedImage([
        {
          image: product?.product_data?.[0]?.thumbnail,
        },
      ]);
    }
  }, [product]);

  // Update available sizes based on selected color and product data
  useEffect(() => {
    setImageId(selectedColor.id);
    const colorSpecificData =
      product?.product_data?.[0]?.productImage?.filter(
        (img) => img.color === selectedColor.color
      ) || [];

    if (colorSpecificData.length > 0) {
      const sizeData = colorSpecificData[0]?.product_image_size || [];
      setAvailableSizes(sizeData);
    }
    if (
      product?.product_data?.[0]?.productImage?.[0]?.product_image_size
        ?.length > 0
    ) {
      let sizeData =
        product?.product_data?.[0]?.productImage?.[0]?.product_image_size?.map(
          (data) => ({
            size: data,
          })
        );
      setSelectedSizeImage(sizeData);
    }
  }, [selectedColor, product]);

  const addToCard = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      Swal.fire({
        title: "Login Required",      
        icon: "info",
        confirmButtonText: "Login",
      }).then((result) => {
        if (result.isConfirmed) {
          handleLoginClick();
        }
      });
      return;
    }
    try {
      var data = {
        product_id: id,
        quantity: quantity,
        product_image_id: imageId,
        product_image_size_id: selectedSize,
        status: "active",
        color: selectedColor.color,
      };
      const res = await PostFormDataRequest("save-cart", JSON.stringify(data));
      if (res) {
        dispatch(getProducts());
         toast.success("Item added to your cart.");        
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };
  const handleLoginClick = () => {
    navigate("/login");
  };

  const handleIncrement = () => setQuantity((prevQty) => prevQty + 1);
  const handleDecrement = () =>
    setQuantity((prevQty) => (prevQty > 1 ? prevQty - 1 : 1));

    const [key, setKey] = useState(0);

    const reloadParent = () => {

      console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>AAAYAA");
      fetchData(id);
    };

  return (
    <>
      <article>
        {product?.product_data?.map((pi) => (
          <div className="container">
            <div className="product-detail" key={pi?.id}>
              <div className="product-images">
                <div className="main-image">
                  <Swiper modules={[Navigation]} navigation={{ clickable: true }} className="mySwiper">
                    {selectedImage.length > 0 &&
                      selectedImage?.map((data, index) => (
                        <SwiperSlide key={index}>
                          <img
                            src={data?.image}
                            alt="Product"
                            style={{borderRadius: "5px"}}
                            onError={(error) =>
                              console.log("Some Error", error)
                            }
                          />
                        </SwiperSlide>
                      ))}
                  </Swiper>
                </div>
                <div className="thumbnail-images">
                  {product?.product_data?.[0]?.productImage?.map(
                    (image, idx) => (
                      <div
                        key={idx}
                        className="thumbnail-item"
                        onClick={() => {
                          setSelectedColor(image);
                          let imageData =
                            image?.multiple_images?.length > 0
                              ? image?.multiple_images?.map((image) => ({
                                  image: ` ${product?.product_data?.[0]?.productImagePath}/${image?.image}`,
                                }))
                              : [
                                  {
                                    image: ` ${product?.product_data?.[0]?.productImagePath}/${image?.image}`,
                                  },
                                ];
                          setSelectedImage(imageData);
                        }}
                      >
                        <img
                          src={` ${pi?.productImagePath}/${image?.image}`}
                          alt=""
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="product-info">
                <div className="product-name">
                  <h1>{pi?.name}</h1>
                  {product.Total_Review ? (
                    <div className="rating">
                      <span>
                        <img src={starIcon}/> {product.avg_Rating ? parseFloat(product.avg_Rating).toFixed(1) : 0.0} ({product.Total_Review ? product.Total_Review  : 0.0})
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <p dangerouslySetInnerHTML={{ __html: pi?.description }}></p>
                <div className="availability">
                  {pi?.available_quantity && pi.available_quantity !== "0" ? (
                    <span><img src={checkedIcon}/> &nbsp; In Stock </span>
                  ) : (
                    <span style={{ color: "red" }}>Out Of Stock</span>
                  )}
                </div>
                {pi?.discounted_price ? (
                  <div className="price">
                    <span className="current-price">
                      ₹{pi.discounted_price}
                    </span>
                    <span className="original-price">₹{pi.price}</span>
                    <span className="discount">-{pi.discount}%</span>
                  </div>
                ) : (
                  <div className="price">
                    <span className="current-price">₹{pi.price}</span>
                  </div>
                )}
                <div className="color-options">
                  <span>CHOOSE THE COLOR</span>
                  <div className="colors">
                    {pi?.productImage?.map((img, index) => (
                      <span
                        key={index}
                        className={`color  ${
                          selectedColor === img?.color ? "selected" : ""
                        }`}
                        style={{ backgroundColor: img?.color }}
                        onClick={() => {
                          setSelectedColor(img);
                          let imageData =
                            img?.multiple_images?.length > 0
                              ? img?.multiple_images?.map((image) => ({
                                  image: ` ${product?.product_data?.[0]?.productImagePath}/${image?.image}`,
                                }))
                              : [
                                  {
                                    image: ` ${product?.product_data?.[0]?.productImagePath}/${img?.image}`,
                                  },
                                ];
                          setSelectedImage(imageData);
                        }}
                      ></span>
                    ))}
                  </div>
                </div>

                <div className="size-options">
                  <span>SIZE</span>
                  <div className="sizes">
                    {availableSizes.map((size, index) => (
                      <span
                        key={index}
                        className={`size  ${
                          selectedSize === size?.size ? "selected" : ""
                        }`}
                        onClick={() => setSelectedSize(size?.size)}
                      >
                        {size?.size}
                      </span>
                    ))}
                  </div>
                </div>

                {/* <div className="quantity">
                  <label htmlFor="qty">QTY</label>
                  <br />
                  <div className="quantity-controls">
                    <button onClick={handleDecrement}>-</button>
                    <input
                      type="number"
                      id="qty"
                      name="qty"
                      min="1"
                      value={quantity}
                      readOnly
                    />
                    <button onClick={handleIncrement}>+</button>
                  </div>
                </div> */}
                <button className="addToCart" onClick={addToCard}>
                  Add to Cart
                </button>
              </div>
            </div>
          </div>
        ))}
      </article>
      <div className="container mt-5">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#overview"
              type="button"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              Overview
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#reviews"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
            >
              Reviews
            </button>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="overview"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <Overview
              product={product}
              data-aos="fade-in"
              data-aos-duration="500"
             
            />
          </div>
          <div
            className="tab-pane fade"
            id="reviews"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <Review product={product} data-aos="fade-in"   reloadParent={reloadParent} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetail;
