import React from "react";
import { NavLink } from "react-bootstrap";
import "./footer.css";

import Logo from "../../assets/logo.svg";
import inboxIcon from "../../assets/mail.svg";
import youtubeIcon from "../../assets/youtube.png";
import facebookIcon from "../../assets/facebook.svg";
import instagramIcon from "../../assets/instagram.svg";

const Footer = () => {
  return (
    // <footer className="footer">
    //   <div className="container links">
    //     <div className="row">
    //     <div className="col-sm-12 col-md-4 col-sm-12 columns">
    //         <NavLink to="/">
    //           <img src={Logo}></img>
    //         </NavLink>
    //       </div>
    //       <div className="col-sm-12 col-md-4 col-sm-12 columns">
    //         <div>
    //           <h3>Legal</h3>
    //           <ul>
    //             {/* <li>
    //               <NavLink to="">FAQ</NavLink>
    //             </li> */}
    //             <li>
    //               <NavLink href="/terms">Terms & Conditions</NavLink>
    //             </li>
    //             <li>
    //               <NavLink href="/privacy">Privacy Policy</NavLink>
    //             </li>
    //           </ul>
    //         </div>
    //       </div>
    //       <div className="col-sm-12 col-md-4 col-sm-12 columns">
    //         <div>
    //           <h3>Address</h3>
    //           <p>
    //           SHIVAM YOGA STUDIO YA-SCHOOL-RYS 200 / 85 RPYS
    //           </p>
    //           <NavLink className="email" href="mailto:shivamyoga@gmail.com" target="_blank">
    //             <img src={inboxIcon} style={{ marginRight: "20px" }} alt="Inbox Icon" />
    //             Shivamyoga@gmail.com
    //           </NavLink>
    //         </div>
    //       </div>
          
    //     </div>
    //   </div>
    //   <hr style={{ border: "2px solid #29254D61" }} />
    //   <div className="footer-bottom">
    //     <div className="container">
    //       <div>
    //         <div>
    //           <ul className="social-icons">
    //             <li>
    //               <NavLink href="https://www.youtube.com/channel/UCB95ewujlU5zM_wwKcALVCA" target="_blank">
    //                 <img className="youtube-icon" src={youtubeIcon}></img>
    //               </NavLink>
    //             </li>
    //             <li>
    //               <NavLink href="https://www.instagram.com/jeevanyogastudio/" target="_blank">
    //                 <img src={instagramIcon}></img>
    //               </NavLink>
    //             </li>
    //             <li>
    //               <NavLink href="https://www.facebook.com/jeevanyogastudio360jeevanyogastudio360/" target="_blank">
    //                 <img src={facebookIcon}></img>
    //               </NavLink>
    //             </li>
    //           </ul>
    //         </div>
    //         <div>
    //           <p className="copyright">
    //             Copyright © Shivam Yoga All Right Reserved.
    //           </p>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </footer>

    <footer className="footers">
    <div className="container">
      <div className="row">
        {/* Logo and Description */}
        <div className="col-sm-12 col-md-6">
          <NavLink to="/">
            <img src={Logo} alt="Shivam Yoga Logo" />
          </NavLink>
          <p>
            Lorem ipsum dolor sit amet consectetur. Purus elementum nisl mauris et in. 
            Libero elementum pretium nisl nisl fermentum malesuada auctor in.
          </p>
        </div>

        {/* About Us Links */}
        <div className="col-sm-12 col-md-3">
          <h3>About Us</h3>
          <ul>
            <li>
              <NavLink href="/about">About Us</NavLink>
            </li>
            <li>
              <NavLink href="/contact">Contact Us</NavLink>
            </li>
            <li>
              <NavLink href="/success-stories">Success Stories</NavLink>
            </li>
            <li>
              <NavLink href="/gallery">Image Gallery</NavLink>
            </li>
            <li>
              <NavLink href="/testimonials">Testimonial</NavLink>
            </li>
          </ul>
        </div>

        {/* Services Links */}
        <div className="col-sm-12 col-md-3">
          <h3>Services</h3>
          <ul>
            <li>
              <NavLink href="/courses">Courses</NavLink>
            </li>
            <li>
              <NavLink href="/plans">Plans</NavLink>
            </li>
            <li>
              <NavLink href="/shop">Shop</NavLink>
            </li>
            <li>
              <NavLink href="/premium">Premium</NavLink>
            </li>
            <li>
              <NavLink href="/offline-centers">Offline Centres</NavLink>
            </li>
          </ul>
        </div>
        <div className="col-sm-12 col-md-6">
            <ul className="social-icons">
              <li>
                <NavLink
                  href="https://www.youtube.com/channel/UCB95ewujlU5zM_wwKcALVCA"
                  target="_blank"
                >
                  <img className="youtube-icon" src={youtubeIcon} alt="YouTube" />
                </NavLink>
              </li>
              <li>
                <NavLink
                  href="https://www.instagram.com/jeevanyogastudio/"
                  target="_blank"
                >
                  <img src={instagramIcon} alt="Instagram" />
                </NavLink>
              </li>
              <li>
                <NavLink
                  href="https://www.facebook.com/jeevanyogastudio360jeevanyogastudio360/"
                  target="_blank"
                >
                  <img src={facebookIcon} alt="Facebook" />
                </NavLink>
              </li>
            </ul>
          </div>
      </div>

      <hr />

      {/* Footer Bottom */}
      <div className="footer-bottom">
        <div className="row">
          
          <div className="col-sm-12 col-md-6 text-right">
            <p className="copyright">
              Copyright © 2024 Shivam Yoga All rights reserved. 
            </p>
          </div>
          <div
            className="col-sm-12 col-md-6 footers-links"
            style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
          >
            <NavLink href="/privacy" style={{ textDecoration: 'none', color: '#333' }}>
              Privacy Policy
            </NavLink>
            <span style={{ color: '#666' }}>/</span>
            <NavLink href="/terms" style={{ textDecoration: 'none', color: '#333' }}>
              Terms of Use
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  </footer>
  );
};

export default Footer;
