import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {GetAllRequest} from "../FormRequest/request";

const initialState = {
  meetOurTeams: [],
  status: "idle",
};

export const getTeamList = createAsyncThunk("listing-team-member/get", async () => {
  try {
    const response=await GetAllRequest('listing-team-member');
    return response?.data || []; 

  } catch (error) {   
    console.error("Failed to fetch products:", error);
    if (error.response && error.response.data) {
      console.error("Error response:", error.response.data); 
    }
    return [];
  }
});

const aboutSlice = createSlice({
  name: "about",
  initialState,
  reducers: {
    fetchTeamList(state, action) {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeamList.fulfilled, (state, action) => {
        state.meetOurTeams = action.payload;
        state.status = "idle";
      })
      .addCase(getTeamList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTeamList.rejected, (state) => {
        state.status = "error";
      });
  },
});

export const { fetchTeamList } = aboutSlice.actions;
export default aboutSlice.reducer;
