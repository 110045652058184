import React, { useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { FaRegClock } from "react-icons/fa";
import { PostFormDataRequest } from "../../FormRequest/request";
import profile from '../../assets/profile.png';
import Swal from "sweetalert2";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// import required modules
import { Navigation } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import "./review.css";

const Review = ({ product, reloadParent }) => {
  const { id: productId } = useParams(); // Fetch the product_id from the URL
  const navigate = useNavigate();
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const [visibleReviews, setVisibleReviews] = useState(2); // Number of reviews to display initially

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token"); // Retrieve the token from localStorage

    if (!token) {
      Swal.fire({
        title: "Login Required",        
        icon: "info",
        confirmButtonText: "Login",
      }).then((result) => {
        if (result.isConfirmed) {
          handleLoginClick();
        }
      });
      return;
    }

    try {
      if(rating){
      const params = {
        product_id: productId,
        rate: rating,
        comment: review,
      };
      const data = await PostFormDataRequest("add-product-review", JSON.stringify(params));
      if (data && data.data) {
        Swal.fire({
          title: "",
          text: "Review submitted successfully.",
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            setRating(0);
            setReview("");
            reloadParent();
          }
        });
        
      }
    }else{
      Swal.fire({
        title: "",
        text: "Please rate your experience on a scale of 1 to 5.",
        icon: "error",
        confirmButtonText: "Ok",
      })
    }
    } catch (error) {
      console.error("Error submitting the review:", error);
    }
  };

  const handleLoginClick = () => {
    navigate("/login");
  };

  const showMoreReviews = () => {
    setVisibleReviews(visibleReviews + 2); // Show 2 more reviews each time
  };

  const showLessReviews = () => {
    setVisibleReviews(2); // Reset to show only 2 reviews
  };

  return (
    <>
      <div className="product-review-container" id="product-review-container">
        <div className="rate-product">
          <h2>Rate this product</h2>
          <div className="stars">
            {[1, 2, 3, 4, 5].map((star) => (
              <span
                key={star}
                className={rating >= star ? "filled" : ""}
                onClick={() => setRating(star)}
                onMouseMove={() => setRating(star)}
              >
                ★
              </span>
            ))}
          </div>
          <form onSubmit={handleSubmit}>
            <textarea
              placeholder="Write a review..."
              value={review}
              onChange={(e) => setReview(e.target.value)}
              required
              max="300"
            />
            <button type="submit">Submit Review</button>
          </form>
        </div>

        <div className="featured-reviews">
          <h3>Featured Reviews</h3>
          {product?.featured_review?.length > 0 ? (
            product.featured_review.map((pi, index) => (
              <div className="review-item" key={index}>
                <div className="top">
                  <div className="wrapper">
                    <div className="dp">
                      <img src={pi?.profile_image || profile} />
                    </div>
                    <div className="info">
                      <p className="reviewer-name">{pi?.name}</p>
                      <p className="date">
                        <FaRegClock />{pi?.created_at}
                      </p>
                    </div>
                  </div>
                  <div className="total-ratings">
                    ⭐ <span>{pi?.rate}</span>
                  </div>
                </div>
                <p className="bottom">
                 {pi.comment}
                </p>
                <div></div>
              </div>
            ))
          ) : (
            <p>No featured reviews available.</p>
          )}
        </div>

        <div className="customer-experience">
          <h3>Customer Experience</h3>
          <div className="total-ratings-and-reviews">
            <p>{product?.Review?.length} Reviews</p>
            <p>
              ⭐⭐⭐⭐⭐ <span>{product?.Review?.total} Ratings</span>
            </p>
          </div>
          {product?.Review?.length > 0 ? (
            product.Review.slice(0, visibleReviews).map((pi, index) => (
              <div className="user-review" key={index}>
                <div className="top">
                  <div className="user-profile-pic">
                    <img src={pi?.profile_image || profile } alt="user1" className="user-pic" />
                  </div>
                  <div className="info">
                    <p className="user-name">{pi.name}</p>
                    <p className="datetime">
                    <FaRegClock /> {pi?.created_at}
                    </p>
                  </div>
                </div>
                <div className="bottom">
                  <p className="review">{pi.comment}</p>
                </div>
              </div>
            ))
          ) : (
            <p>No customer reviews available.</p>
          )}

          {product?.Review?.length > visibleReviews && (
            <button className="more-reviews" onClick={showMoreReviews}>
              Show More Reviews
            </button>
          )}
          {visibleReviews > 2 && (
            <button className="more-reviews" style={{marginLeft:"10px"}} onClick={showLessReviews}>
              Show Less Reviews
            </button>
          )}
        </div>

        <div className="related-products">
          <h3>Related products</h3>
          <Swiper
            spaceBetween={20}
            slidesPerView={4}
            modules={[Navigation]}
            breakpoints={{
              1440: { slidesPerView: 4 },
              1200: { slidesPerView: 4 },
              1024: { slidesPerView: 3 },
              768: { slidesPerView: 2 },
              576: { slidesPerView: 1 },
              320: { slidesPerView: 1 },
            }}
          >
            {product?.relatedProducts?.map((pi, index) => (
              <SwiperSlide key={index}>
                <div className="product-card">
                  <img
                    src={pi.thumbnail}
                    alt={pi.name}
                    className="product-image"
                  />
                  <div className="product-info">
                    <p className="product-name">{pi.name}</p>
                    <div className="product-price">
                      {pi.originalPrice && (
                        <span className="original-price">
                           ₹{pi.originalPrice}
                        </span>
                      )}
                      {pi.discountedPrice && (
                        <span className="discounted-price">
                           ₹{pi.discountedPrice}
                        </span>
                      )}
                    </div>
                    <div className="product-discount">{pi.discount}% Off</div>
                    <div className="color-options">
                      {pi.colors?.map((color, index) => (
                        <span
                          key={index}
                          className="color-dot"
                          style={{ backgroundColor: color }}
                        ></span>
                      ))}
                    </div>
                    <button className="add-to-cart-btn">Add to Cart</button>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        {showLoginPrompt && (
          <div className="login-prompt">
            <p>Please log in first before submitting a review.</p>
            <button onClick={handleLoginClick}>Login</button>
          </div>
        )}
      </div>
    </>
  );
};

export default Review;
